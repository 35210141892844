import React from "react";
import { css } from "@emotion/core";
import { OutboundLink } from "gatsby-plugin-gtag";
import { Desktop, Tablet } from "../../constants/responsive";
import Container from "../Container";

import RightArrow from "../../svgs/icons/right-arrow.svg";

import Instagram from "../../svgs/social/instagram.svg";
import HKSTP from "../../svgs/social/hkstp.svg";
import LinkedIn from "../../svgs/social/linkedin.svg";
import Youtube from "../../svgs/social/youtube.svg";
import { FormattedHTMLMessage, useIntl } from "gatsby-plugin-intl";

const Footer = () => {
  const intl = useIntl();
  return (
    <footer
      id="contact"
      css={css`
        display: flex;
        justify-content: center;
        align-items: center;
        color: #bdbdbd;
        background: #212121;
        padding-top: 40px;
        padding-bottom: 30px;
        min-width: 320px;
        ${Desktop} {
          padding-top: 80px;
          padding-bottom: 60px;
        }
      `}
    >
      <Container>
        <div
          css={css`
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            ${Tablet} {
              justify-content: space-between;
              flex-direction: row;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              position: relative;
            `}
          >
            <h2
              css={css`
                margin-bottom: 10px;
                color: white;
              `}
            >
              <FormattedHTMLMessage id="updated" />
            </h2>

            <div id="mc_embed_signup">
              <form
                action="https://ricerobotics.us2.list-manage.com/subscribe/post?u=9d4efb84853022de6dd2d99e5&amp;id=b5cf9938b8"
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                autoComplete="off"
                noValidate
              >
                <div
                  css={css`
                    position: relative;
                    width: 100%;
                  `}
                  id="mc_embed_signup_scroll"
                >
                  <RightArrow
                    css={css`
                      width: 12px;
                      height: 24px;
                      position: absolute;
                      fill: #fff;
                      right: 0px;
                      bottom: 8px;
                      ${Desktop} {
                        width: 20px;
                        position: absolute;
                        height: 30px;
                        bottom: 11px;
                      }
                    `}
                  />
                  <div>
                    <input
                      type="email"
                      name="EMAIL"
                      id="mce-EMAIL"
                      css={css`
                        background: transparent;
                        font-size: 16px;
                        width: 280px;
                        padding: 10px 40px 10px 0px;
                        border-bottom: 1px solid white;
                        color: white;
                        ${Tablet} {
                          width: 180px;
                        }
                        ${Desktop} {
                          padding: 10px 40px 10px 0px;
                          font-size: 28px;
                          width: 400px;
                        }
                      `}
                      placeholder={intl.formatMessage({ id: "email" })}
                    />
                  </div>
                  <div>
                    <input
                      type="submit"
                      name="subscribe"
                      id="mc-embedded-subscribe"
                      css={css`
                        cursor: pointer;
                        ${Desktop} {
                          width: 41px;
                        }
                        height: 100%;
                        opacity: 0;
                        position: absolute;
                        top: 0px;
                        right: 0px;
                      `}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div
            css={css`
              display: flex;
              margin-top: 40px;
              flex-direction: column;
              ${Tablet} {
                margin: 0px;
                flex-direction: row;
              }
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column-reverse;
                width: 280px;
                align-items: center;
                margin-right: 0px;
                ${Tablet} {
                  width: auto;
                  flex-direction: column;
                  align-items: flex-start;
                  margin-right: 140px;
                }
              `}
            >
              <OutboundLink href="tel:+85296475835">
                <p>+852 9647 5835</p>
              </OutboundLink>
              <OutboundLink href="mailto:hello@ricerobotics.com">
                <p> hello@ricerobotics.com </p>
              </OutboundLink>

              <div
                css={css`
                  svg,
                  path {
                    fill: #bdbdbd;
                  }
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  margin-top: 20px;
                  margin-bottom: 40px;
                  width: 240px;
                  ${Tablet} {
                    margin-top: 30px;
                    margin-bottom: 0px;
                    width: 100%;
                  }
                `}
              >
                <OutboundLink
                  href="https://www.hkstp.org/en/our-partner-companies/company-directory/company-details/?company=rice-robotics-limited"
                  target="_blank"
                  rel="noreferrer"
                >
                  <HKSTP
                    css={css`
                      width: 28px;
                      ${Tablet} {
                        width: 20px;
                      }
                      ${Desktop} {
                        width: 24px;
                      }
                    `}
                  />
                </OutboundLink>
                <OutboundLink
                  href="https://hk.linkedin.com/company/ricerobotics"
                  target="_blank"
                  rel="noreferrer"
                >
                  <LinkedIn
                    css={css`
                      width: 28px;
                      ${Tablet} {
                        width: 20px;
                      }
                      ${Desktop} {
                        width: 24px;
                      }
                    `}
                  />
                </OutboundLink>
                <OutboundLink
                  href="https://www.youtube.com/channel/UCz2jMaIlZZ2p8CbxQZa5U6g"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Youtube
                    css={css`
                      width: 28px;
                      ${Tablet} {
                        width: 20px;
                      }
                      ${Desktop} {
                        width: 24px;
                      }
                    `}
                  />
                </OutboundLink>
                <OutboundLink
                  href="https://www.instagram.com/riceroboticsofficial/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Instagram
                    css={css`
                      width: 28px;
                      ${Tablet} {
                        width: 20px;
                      }
                      ${Desktop} {
                        width: 24px;
                      }
                    `}
                  />
                </OutboundLink>
              </div>
            </div>
            <p
              css={css`
                margin-top: 30px;
                text-align: center;
                line-height: 1.5;
                ${Tablet} {
                  text-align: left;
                  margin: 0;
                }
              `}
            >
              <FormattedHTMLMessage id="address" />
            </p>
          </div>
        </div>
        <div
          css={css`
            width: 100%;
            text-align: center;
            color: grey;
            margin-top: 40px;
            font-size: 12px;
            ${Desktop} {
              margin-top: 80px;
              font-size: 14px;
            }
          `}
        >
          2019 - 2023 Rice Robotics Ltd. |&nbsp;
          <OutboundLink
            href="/documents/rice-privacy-policy.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <FormattedHTMLMessage id="privacy" />
          </OutboundLink>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
